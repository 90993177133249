import {
	Overlay,
	OverlayPositionBuilder,
	OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
	ComponentRef,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { delay, mergeMap, of, Subscription, takeUntil } from 'rxjs';
import { AppTooltipComponent } from '../components/tooltip/app-tooltip.component';

@Directive({
	selector: '[appTooltip]',
	standalone: true,
})
export class AppTooltipDirective implements OnInit, OnDestroy {
	private overlayRef: OverlayRef;
	private DELAYTIME = 300;
	@Input('appTooltip') text = '';
	private _mouseEnterStream: EventEmitter<any> = new EventEmitter();
	private _mouseLeaveStream: EventEmitter<any> = new EventEmitter();
	private subs: Subscription = new Subscription();

	constructor(
		private overlayPositionBuilder: OverlayPositionBuilder,
		private elementRef: ElementRef,
		private overlay: Overlay,
	) {}

	ngOnDestroy(): void {
		if (this.overlayRef.hasAttached()) {
			this.overlayRef.detach();
		}
		this.subs.unsubscribe();
	}

	positionStrategyTop() {
		return this.overlayPositionBuilder
			.flexibleConnectedTo(this.elementRef)
			.withPositions([
				{
					originX: 'center',
					originY: 'top',
					overlayX: 'center',
					overlayY: 'bottom',
					offsetY: -8,
				},
			]);
	}

	positionStrategyBottom() {
		return this.overlayPositionBuilder
			.flexibleConnectedTo(this.elementRef)
			.withPositions([
				{
					originX: 'center',
					originY: 'top',
					overlayX: 'center',
					overlayY: 'bottom',
					offsetY: 40,
				},
			]);
	}

	ngOnInit(): void {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.positionStrategyTop(),
		});

		this.subs.add(
			this._mouseEnterStream
				.pipe(
					mergeMap((e) => {
						return of(e).pipe(
							delay(this.DELAYTIME),
							takeUntil(this._mouseLeaveStream),
						);
					}),
				)
				.subscribe((_) => {
					// Create tooltip portal
					const tooltipPortal = new ComponentPortal(
						AppTooltipComponent,
					);

					const positionStrategy =
						window.scrollY +
							this.elementRef.nativeElement.getBoundingClientRect()
								.top <
						40
							? this.positionStrategyBottom()
							: this.positionStrategyTop();

					this.overlayRef = this.overlay.create({ positionStrategy });

					// Attach tooltip portal to overlay
					const tooltipRef: ComponentRef<AppTooltipComponent> =
						this.overlayRef.attach(tooltipPortal);

					// Pass content to tooltip component instance
					tooltipRef.instance.text = this.text;
				}),
		);

		this.subs.add(
			this._mouseLeaveStream.subscribe((_) => this.overlayRef.detach()),
		);
	}

	@HostListener('mouseenter')
	show() {
		this._mouseEnterStream.emit();
	}

	@HostListener('mouseleave')
	hide() {
		this._mouseLeaveStream.emit();
	}
}
