import {
	animate,
	query,
	stagger,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-tooltip',
    template: `<div @tooltip>{{ text }}</div> `,
    styles: [
        `
			div {
				display: block;
				background-color: #292929;
				color: white;
				padding: 0.5rem 1rem;
				border-radius: 4px;
				max-width: 300px;
			}
		`,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('tooltip', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(300, style({ opacity: 1 })),
            ]),
            transition(':leave', [animate(300, style({ opacity: 0 }))]),
        ]),
    ],
    standalone: false
})
export class AppTooltipComponent {
	@Input() text = '';
}
